import React, { useState, useEffect } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import useWindowSize from "../../utilt/useWindowSize"

//CSS
import { eachTitle } from "./grids.module.css"
import { desc0 } from "./grids.module.css"
import { desc } from "./grids.module.css"
import { thumNails } from "./grids.module.css"
import { thumNail } from "./grids.module.css"
import { curThumbImgDiv } from "./grids.module.css"
import { thumbImgDiv } from "./grids.module.css"
import { thumbImg } from "./grids.module.css"
import { thumbDesc } from "./grids.module.css"
import { thumNailUme } from "./grids.module.css"
import { modalArea } from "./grids.module.css"
import { isShowCSS } from "./grids.module.css"
import { modalBg } from "./grids.module.css"
import { modalWrapper } from "./grids.module.css"
import { modalWrapperScale } from "./grids.module.css"
import { modalContentCSS } from "./grids.module.css"
import { modalContentImg } from "./grids.module.css"
import { imgHide } from "./grids.module.css"
import { imgAppear } from "./grids.module.css"
import { close } from "./grids.module.css"

import getLanguage from "../../utilt/getLangueage"
import calcModalSize from "../../utilt/calcModalSize"
//import getModalEventHol from "../../utilt/getModalEventHol"
import getIsTouchscreen from "../../utilt/getIsTouchscreen"

//import seoImg from "../../images/seo/seo_grids.jpg"



/**
 * ページ
 */
const Work4 = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }


  //画像データ取得
  const data = useStaticQuery(graphql`query QueryGrids {
  allFile(
    filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "grids"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(quality: 50, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
    }
  }
  allGridsJson {
    edges {
      node {
        title
        description
        descriptionJp
      }
    }
  }
}
`)

  //1 取得した画像データを配列化
  const imagesOld = data.allFile.edges.map(n => n.node);
  const images = imagesOld.slice();
  //2 ファイル名でソート
  function compare(a, b) {
    const baseA = a.base;
    const baseB = b.base;

    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  images.sort(compare);
  for (let i = 0; i < images.length; i++) {
    images[i].index = i;
  }
  //3 配列を二分してサムネイルとモーダル表示用画像を分ける
  const ImagesLength = Math.floor(images.length / 2);
  const modalImages = images.slice(0, ImagesLength);
  const thumbsImages = images.slice(ImagesLength);
  //4 サムネイルにindexを付ける
  for (let i = 0; i < ImagesLength; i++) {
    modalImages[i].index = i;
    thumbsImages[i].index = i;
  }

  //5 JSONデータを配列化
  const descJsons = data.allGridsJson.edges.map(n => n.node);
  //6 JSONもファイル名でソート
  function compareJason(a, b) {
    const titleA = a.title;
    const titleB = b.title;

    let comparison = 0;
    if (titleA > titleB) {
      comparison = 1;
    } else if (titleA < titleB) {
      comparison = -1;
    }
    return comparison;
  }
  descJsons.sort(compareJason);
  for (let i = 0; i < descJsons.length; i++) {
    descJsons[i].index = i;
  }

  /**
   * モダール用
   */
  const [isShow, setIsShow] = useState(false);
  const [picNum, setPicNum] = useState(0);
  const [isBack, setIsBack] = useState(false); //modalのback(left arrow)用

  function handleModal(event, index) {
    event.stopPropagation();
    if (typeof index == "number") {
      setPicNum(index);
    }
    setIsShow(!isShow);
  }

  function handleModalKey(event, index) {
    if (event.keyCode === 13) { //Enter key
      if (typeof index == "number") {
        setPicNum(index);
      }
      setIsShow(true);
    }
  }


  function clickModal(event) {
    event.stopPropagation();
    const modal = document.getElementById("modalContent");
    const clientRect = modal.getBoundingClientRect();

    if (event.clientX > clientRect.left + clientRect.width * 0.5) {
      setPicNum(picNum => (picNum + 1) % ImagesLength);
    } else {
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
    }
  }


  //for touchscreen
  useEffect(() => {
    function plusPicNum() {
      setPicNum(picNum => (picNum + 1) % ImagesLength);
      setIsBack(false);
    }

    function minusPicNum() {
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
      setIsBack(true);
    }

    let handleTouchStart, handleTouchMove, handleTouchEnd;
    if (getIsTouchscreen()) {
      let startX;
      let moveX;
      let dist = 150;
      let isStart = false;

      handleTouchStart = e => {
        //e.preventDefault();
        if (!isStart) {
          isStart = true;
          startX = e.touches[0].pageX;
        }
      }
      handleTouchMove = e => {
        //e.preventDefault();
        moveX = e.changedTouches[0].pageX;
      }
      handleTouchEnd = e => {
        if (isStart) {
          isStart = false;
          if (startX && moveX) {
            if (startX > moveX && startX > moveX + dist) { // 右から左にスワイプ
              //e.preventDefault()
              plusPicNum();
            } else if (startX < moveX && startX + dist < moveX) { // 左から右にスワイプ
              //e.preventDefault()
              minusPicNum();
            }
            startX = null;
            moveX = null;
          }
        }
      }

      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (getIsTouchscreen()) {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [picNum, ImagesLength])


  //キーボード操作でモダール画像チェンジ
  useEffect(() => {
    function handlePicNumKey(event) {
      if (event.key === "ArrowRight" || event.key === "Tab") {
        let result = (picNum + 1) % ImagesLength;
        setPicNum(result);
      } else if (event.key === "ArrowLeft") {
        let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
        setPicNum(result);
      } else if (event.keyCode === 27) {
        setIsShow(false);
      }
    }

    //画像選択にfocusをシンクロさせる
    if (isShow) {
      document.getElementById(`pic${String(picNum)}`).focus();
      //console.log(document.getElementById(`pic${String(picNum)}`));
    }

    window.addEventListener('keydown', handlePicNumKey);
    return () => {
      window.removeEventListener('keydown', handlePicNumKey);
    }
  }, [picNum, isShow, ImagesLength])


  const modaleScale = 2.2;

  return <>
    <Seo
      title={lang === "en" ? "Grids Drawing" : "グリッド絵"}
      description={lang === "en" ?
        "Drawings in which paper with squares are filled with small pictures drawn by the technique of Automatism" :
        "升目のある紙をオートマティスムの技法で描いた小さな絵で埋めていったドローイング"
      }
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_grids.jpg"
      pathname="/work/grids"
    />

    {/**
       * ページ＆サムネイル
       */}
    <Layout
      checked="worksParts"
      winSize={winSize}
    >
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <h1 className={eachTitle}>
          {lang === "en" ? "Grids Drawing" : "グリッド絵"}
        </h1>
        <p className={desc0}>
          {lang === "en" ?
            `Series title："Where do they come from and how many?"` :
            `シリーズタイトル：「何人(Where do they come from and how many?)」`}
        </p>
        <p className={desc}>
          {lang === "en" ?
            `ink on paper 2002-` :
            `インク、紙　2002年～`}
        </p>
        <div
          className={thumNails}
        >
          {thumbsImages.map((node) => (
            <div
              className={thumNail}
              onClick={event => handleModal(event, node.index)}
              onKeyDown={event => handleModalKey(event, node.index)}
              role="button"
              tabIndex={0}
              key={node.base.split(".")[0]}
              id={"pic" + String(node.index)}
            >
              <div
                className={isShow && picNum === node.index ? curThumbImgDiv : thumbImgDiv}
                style={{
                  width: `${winSize.width < 400 ?
                    node.childImageSharp.gatsbyImageData.width * 0.4 :
                    node.childImageSharp.gatsbyImageData.width * 0.5}px`,
                }}
              >
                <div
                  className={thumbImg}
                  style={{
                    width: `${winSize.width < 400 ?
                      node.childImageSharp.gatsbyImageData.width * 0.4 :
                      node.childImageSharp.gatsbyImageData.width * 0.5}px`,
                  }}
                >
                  <GatsbyImage
                    image={node.childImageSharp.gatsbyImageData}
                    title={lang === "en" ?
                      descJsons[node.index].description :
                      descJsons[node.index].descriptionJp}
                    alt={node.base.split(".")[0]} />
                </div>
              </div>
              <p className={thumbDesc}>
                {lang === "en" ?
                  descJsons[node.index].description :
                  descJsons[node.index].descriptionJp}
              </p>
            </div>
          ))}
          <div className={thumNailUme}> </div>
        </div>
      </Article>
    </Layout>


    {/**
       * モーダル
      */}
    <div
      className={
        isShow ? `${modalArea} ${isShowCSS}` : modalArea
      }
    >
      <div
        className={modalBg}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        <div
          className={
            isShow ? `${modalWrapper} ${modalWrapperScale}` : modalWrapper
          }
          style={{
            width:
              calcModalSize(winSize, modalImages[picNum].childImageSharp.gatsbyImageData.width * 0.2,
                modalImages[picNum].childImageSharp.gatsbyImageData.width / modalImages[picNum].childImageSharp.gatsbyImageData.height, modaleScale,
                0.9 + 0.15 * Math.min((modalImages[picNum].childImageSharp.gatsbyImageData.height * 0.2) / winSize.height), 1) + "px"
          }}
        >
          <div
            className={modalContentCSS}
            onClick={event => clickModal(event)}
            onKeyDown={() => { }}
            role="button"
            tabIndex={0}
            id="modalContent"
          >

            <div
              className={`${modalContentImg} ${imgHide}`}
            >
              <GatsbyImage
                image={modalImages[picNum % 2 === 0 ? picNum : (!isBack ? (picNum + 1 >= ImagesLength ? 0 : picNum + 1) : picNum - 1)].childImageSharp.gatsbyImageData}
                alt="forPreLoad1" />
            </div>

            <div
              className={`${modalContentImg} ${imgHide}`}
            >
              <GatsbyImage
                image={modalImages[picNum % 2 === 1 ? picNum :
                  (!isBack ? picNum + 1 : picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1)].childImageSharp.gatsbyImageData}
                alt="forPreLoad2" />
            </div>

            <div
              className={picNum % 2 === 0 ? `${modalContentImg} ${imgAppear}` : ` ${modalContentImg} ${imgHide}`}
            >
              <GatsbyImage
                image={modalImages[picNum % 2 === 0 ? picNum : (isBack ? (picNum + 1 >= ImagesLength ? 0 : picNum + 1) : picNum - 1)].childImageSharp.gatsbyImageData}
                alt={modalImages[picNum].base.split(".")[0]} />
            </div>

            <div
              className={picNum % 2 === 1 ? `${modalContentImg} ${imgAppear}` : `${modalContentImg} ${imgHide}`}
            >
              <GatsbyImage
                image={modalImages[picNum % 2 === 1 ? picNum :
                  (isBack ? picNum + 1 : picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1)].childImageSharp.gatsbyImageData}
                alt={modalImages[picNum].base.split(".")[0]} />
            </div>


          </div>

        </div>
        <span
          className={close}
          onClick={event => handleModal(event)}
          onKeyDown={() => { }}
          role="button"
          tabIndex={0}
        >
          ×
        </span>
      </div>
    </div>
  </>;
}

export default Work4



